import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import MentalHealth from "../../../img/mental-services.jpg";
import AlcoholUse from "../../../img/alcohol-support.jpg";
import BehaviourTreatment from "../../../img/behaviour-treatment.jpg";
import TelePsychiatry from "../../../img/tele-psychiatry.jpg";
const Services = () => {
  return (
    <Fragment>
      <div className="bg-primary-3 o-hidden" data-overlay>
        <section className="text-white pb-0">
          <div className="container pb-5">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-9">
                <h1 className="display-3">
                  Take Charge{" "}
                  <mark data-aos="highlight-text" data-aos-delay="250">
                    of Your Life
                  </mark>
                </h1>
                <p className="lead mb-5">
                  Providing hope and achieving change, wellness and recovery
                  during life’s difficult times.
                </p>
              </div>
            </div>
          </div>
          {/*<div
            className="position-absolute w-50 h-100 top left"
            data-jarallax-element="100 50"
          >
            <div className="blob blob-4 bg-gradient w-100 h-100 top left"></div>
          </div>
           <div className="divider divider-bottom bg-white"></div> */}
        </section>
      </div>
      <section className="pb-0">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-9">
              <h3 className="display-4">
                Our services,{" "}
                <mark data-aos="highlight-text" data-aos-delay="250">
                  tailored to you!
                </mark>
              </h3>
            </div>
          </div>

          {/* <div className="row justify-content-center mt-4 mt-md-5">
            <div className="col-auto">
              <div className="alert bg-secondary">
                Getting started is simple.
                <Link to="/new-patients">
                  {" "}
                  take a free mental health assessment
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row align-items-center justify-content-around text-center text-lg-left">
            <div
              className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 order-lg-2"
              data-aos="fade-in"
            >
              <h2 className="h1">Mental Health Services</h2>
              <p className="lead">
                <ul>
                  <li>
                    Diagnostic Evaluation and establishing diagnoses according
                    to DSM V criteria
                  </li>
                  <li>
                    Prescribing and monitoring of Psychotropic Medications
                    Management
                  </li>
                  <li>
                    Providing psychotherapy – individual counseling, couple
                    therapy and family therapy
                  </li>{" "}
                  <li>Measurement</li>
                </ul>
              </p>
              <Link to="/new-patients" className="lead">
                Get Started
              </Link>
            </div>
            <div
              className="col-md-9 col-lg-6 col-xl-5 order-lg-1"
              data-aos="fade-in"
            >
              <img
                src={MentalHealth}
                alt="Mental Health Services"
                className="img-fluid rounded shadow"
              />
              {/* <img
                src="assets/img/about-2a.jpg"
                alt="About"
                className="position-absolute p-0 col-4 col-xl-5 border border-white border-thick rounded-circle top left shadow-lg mt-5 ml-n5 ml-lg-n3 ml-xl-n5 d-none d-md-block"
                data-jarallax-element="-20 0"
              /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0 o-hidden">
        <div className="container">
          <div className="row align-items-center justify-content-around text-center text-lg-left">
            <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
              <div data-aos="fade-in" data-aos-offset="250">
                <h2 className="h1">Alcohol and Drug Addiction Services</h2>
                <p className="lead">
                  <ul>
                    <li>
                      Assessment and management of substance use and Substance
                      related disorders
                    </li>
                    <li>
                      Pharmacological Management of Alcohol-, Nicotine-,
                      Opioid-, and Prescription pills (pain medications and
                      benzodiazepines) use disorders.
                    </li>
                  </ul>
                </p>
                <Link to="/new-patients" className="lead">
                  Get Started
                </Link>
              </div>
            </div>
            <div
              className="col-md-9 col-lg-6 col-xl-5"
              data-aos="fade-in"
              data-aos-offset="250"
            >
              <img
                src={AlcoholUse}
                alt="Alcohol and Drug Addiction Services"
                className="img-fluid rounded shadow"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary-3 text-white pt-0 mb-0 pb-0">
        {/* <div className="divider divider-top transform-flip-x bg-light"></div> */}
        <div className="container">
          <section>
            <div className="container">
              <div className="row align-items-center justify-content-around text-center text-lg-left">
                <div
                  className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 order-lg-2"
                  data-aos="fade-in"
                >
                  {" "}
                  <h2 className="h1">Behavioral Addictions Treatment</h2>
                  <p className="lead">
                    <ul>
                      <li>Compulsive Gambling</li>
                      <li>Internet Addiction</li>
                    </ul>
                  </p>
                  <Link to="/new-patients" className="lead">
                    Get Started
                  </Link>
                </div>
                <div
                  className="col-md-9 col-lg-6 col-xl-5 order-lg-1"
                  data-aos="fade-in"
                >
                  <img
                    src={BehaviourTreatment}
                    alt="Behavioral Addictions Treatment"
                    className="img-fluid rounded shadow"
                  />
                  {/* <img
                src="assets/img/about-2a.jpg"
                alt="About"
                className="position-absolute p-0 col-4 col-xl-5 border border-white border-thick rounded-circle top left shadow-lg mt-5 ml-n5 ml-lg-n3 ml-xl-n5 d-none d-md-block"
                data-jarallax-element="-20 0"
              /> */}
                </div>
              </div>
            </div>
            <div className="container pt-5">
              <div className="row align-items-center justify-content-around text-center text-lg-left">
                <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
                  <div data-aos="fade-in" data-aos-offset="250">
                    <h2 className="h1">Tele-psychiatry Services</h2>
                    <p className="lead">
                      Provision of the above service through HIPAA compliant
                      secure video-conferencing platform to allow client be
                      conveniently seen safely, securely away from the office
                      clinic even during periods of inclement weather or public
                      health emergency such as the current COVID -10 Pandemic
                    </p>
                    <Link to="/new-patients" className="lead">
                      Get Started
                    </Link>
                  </div>
                </div>
                <div
                  className="col-md-9 col-lg-6 col-xl-5"
                  data-aos="fade-in"
                  data-aos-offset="250"
                >
                  <img
                    src={TelePsychiatry}
                    alt="About"
                    className="img-fluid rounded shadow"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Fragment>
  );
};
export default Services;
