import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Resources = () => {
  return (
    <Fragment>
      <div className="bg-primary-3 o-hidden" data-overlay>
        <section className="text-white pb-0">
          <div className="container pb-5">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-9">
                <h1 className="display-3">
                  Take Charge{" "}
                  <mark data-aos="highlight-text" data-aos-delay="250">
                    of Your Life
                  </mark>
                </h1>
                <h3>
                  Providing hope and achieving change, wellness and recovery
                  during life’s difficult times.
                </h3>
              </div>
            </div>
          </div>
          {/*<div
            className="position-absolute w-50 h-100 top left"
            data-jarallax-element="100 50"
          >
            <div className="blob blob-4 bg-gradient w-100 h-100 top left"></div>
          </div>
           <div className="divider divider-bottom bg-white"></div> */}
        </section>
      </div>
      {/* <section className="pb-0">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-9">
              <h3 className="display-4">
                Our services,{" "}
                <mark data-aos="highlight-text" data-aos-delay="250">
                  tailored to you!
                </mark>
              </h3>
            </div>
          </div>

          {/* <div className="row justify-content-center mt-4 mt-md-5">
            <div className="col-auto">
              <div className="alert bg-secondary">
                Getting started is simple.
                <Link to="/new-patients">
                  {" "}
                  take a free mental health assessment
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="pb-0 bg-light">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-7">
              <h3 className="display-4">In case of an emergency</h3>
              <div className="lead">
                If you are having a psychiatric or medical emergency, please
                CALL <mark>911</mark> or go directly to your nearest emergency
                department if you feel safe to do so.
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-9 col-md-10">
              {/* <div className="px-3 d-none d-md-block">
                <div className="row mb-4">
                  <div className="col">
                    <h5 className="mb-0">Position</h5>
                  </div>
                  <div className="col-3">
                    <h5 className="mb-0">Location</h5>
                  </div>
                </div>
              </div> */}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    Designated Screening Services in New Jersey for Psychiatric
                    emergencies, crisis intervention services, information, and
                    referral <br />
                    <br />
                    <a
                      href="https://www.state.nj.us/humanservices/dmhas/home/hotlines/MH_Screening_Centers.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ENGLISH
                    </a>
                    {"  |  "}
                    <a
                      href="https://www.state.nj.us/humanservices/dmhas/home/hotlines/MH_Screening_Centers_Spanish.pdf "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SPANISH
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    National Center for PTSD <br />
                    <br />
                    <a
                      href="https://www.ptsd.va.gov/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit Website
                    </a>
                    {"  |  "}
                    <a
                      href="tel:1-800-273-8255"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CALL 1-800-273-8255
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    National Domestic Violence Hotline <br />
                    <br />
                    <a
                      href="tel:1-800-799-7233"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CALL 1-800-799-7233
                    </a>
                    {"  or "}
                    <a
                      href="tel:1-800-787-3224"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CALL 1-800-787-3224 (TTY)
                    </a>
                  </h6>
                </div>
              </div>
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    Suicide Prevention Brochure <br />
                    <br />
                    <a
                      href="hhttps://www.state.nj.us/humanservices/dmhas/resources/services/prevention/Suicide%20Prevention%20brochure%202019-DHS.pdf "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ENGLISH
                    </a>
                    {"  |  "}
                    <a
                      href=" https://www.state.nj.us/humanservices/dmhas/resources/services/prevention/Suicide%20Is%20Preventable%20SPANISH.pdf ters_Spanish.pdf "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SPANISH
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    Addiction Services Treatment Directory <br />
                    <br />
                    <a
                      href="https://njsams.rutgers.edu/TreatmentDirectory/License"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      VISIT WEBSITE
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    New Jersey MentalHealthCares, 1-866-202-HELP (4357)
                    <br />
                    <br />
                    <a
                      href="tel:1-866-202-4357"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CALL 1-866-202-HELP (4357)
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    Warmline Mental Health Association of New Jersey
                    1-877-292-5588
                    <br />
                    <br />
                    <a
                      href="tel:1-877-292-5588"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CALL 1-877-292-5588
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    NJ Division of Mental Health and Addiction Services
                    Community Wellness Centers
                    <br />
                    <br />
                    <a
                      href="https://www.state.nj.us/humanservices/dmhas/home/hotlines/DMHAS_Community_Wellness%20Centers.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      DOWNLOAD
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    State of NJ Directory of Mental Health Services
                    <br />
                    <br />
                    <a
                      href="https://www.state.nj.us/humanservices/dmhas/home/hotlines/MH_Dir_COMPLETE.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      DOWNLOAD
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    National Center for PTSD, 1-800-273-8255 <br />
                    <br />
                    <a
                      href="tel:1-800-273-8255"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CALL 1-800-273-8255
                    </a>
                    {"  |  "}
                    <a
                      href="https://www.ptsd.va.gov/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      VISIT WEBSITE
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    VA Veteran HOTLINE, 1-800-273-8255 <br />
                    <br />
                    <a
                      href="tel:1-800-273-8255"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CALL 1-800-273-8255
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    National Suicide Prevention Lifeline 1-800-272-TALK (8255) -
                    – free and confidential support for people in distress, 24/7
                    (From July 16, 2022, Just dial 988) <br />
                    <br />
                    <a
                      href="tel:1-800-273-8255"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CALL 1-800-272-TALK (8255)
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    National Domestic Violence Hotline 1-800-799-7233 |
                    1-800-787-3224 (TTY) <br />
                    <br />
                    <a
                      href="tel:1-800-799-7233"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CALL 1-800-799-7233
                    </a>
                    {"  |  "}
                    <a
                      href="tel:1-800-787-3224"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CALL 1-800-787-3224 (TTY)
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    National Alliance On Mental Illness <br />
                    <br />
                    <a
                      href="https://www.nami.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      VISIT WEBSITE
                    </a>
                  </h6>
                </div>
              </div>{" "}
              <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                <div className="col-auto col-md">
                  <h6 className="mb-0">
                    Substance Abuse and Mental Health Service Administration{" "}
                    <br />
                    <br />
                    <a
                      href="https://www.samhsa.gov/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      VISIT WEBSITE
                    </a>
                  </h6>
                </div>
              </div>{" "}
            </div>
          </div>
          {/*
          <div className="row justify-content-center mt-3 mt-md-4">
            <div className="col-auto">
              <div className="alert bg-secondary">
                Don't see a good fit?{" "}
                <Link to="#">Submit your r&eacute;sum&eacute;</Link>
              </div>
            </div>
          </div>
        </div>
         <div className="divider divider-bottom bg-primary-3"></div> */}
        </div>
      </section>
    </Fragment>
  );
};
export default Resources;
