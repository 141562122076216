import React, { Fragment } from "react";
import LOCs from "../modules/LOCs";
const LevelOfCare = () => {
  return (
    <Fragment>
      <Fragment>
        <div className="bg-primary-3 o-hidden" data-overlay>
          <section className="text-white pb-0">
            <div className="container pb-5">
              <div className="row justify-content-center text-center">
                <div className="col-md-9 col-lg-8 col-xl-9">
                  <h1 className="display-3">Level of Care</h1>
                  {/* <h3>
                    Understanding the Levels of Psychiatric Care is Key to
                    Treatment Success
                  </h3> */}
                </div>
              </div>
            </div>
            {/*<div
            className="position-absolute w-50 h-100 top left"
            data-jarallax-element="100 50"
          >
            <div className="blob blob-4 bg-gradient w-100 h-100 top left"></div>
          </div>
           <div className="divider divider-bottom bg-white"></div> */}
          </section>
        </div>
        {/* <section className="pb-0">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-9">
              <h3 className="display-4">
                Our services,{" "}
                <mark data-aos="highlight-text" data-aos-delay="250">
                  tailored to you!
                </mark>
              </h3>
            </div>
          </div>

          <div className="row justify-content-center mt-4 mt-md-5">
            <div className="col-auto">
              <div className="alert bg-secondary">
                Getting started is simple.
                <Link to="/new-patients">
                  {" "}
                  take a free mental health assessment
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
        <section>
          <div className="container">
            <div className="row align-items-center justify-content-around text-center text-lg-left">
              <div
                className="col-md-9 col-lg-9 col-xl-9 mb-4 mb-md-5 mb-lg-0 order-lg-2"
                data-aos="fade-in"
              >
                <h2 className="h1">
                  {" "}
                  Understanding the Levels of Psychiatric Care is Key to
                  Treatment Success
                </h2>
                <p className="lead">
                  Many patients and families often ask why their outpatient
                  treatment is not working. Is it that the
                  psychiatrist/therapist is incompetent? Is their family member
                  simply “not trying hard enough?” Often, the answer to this
                  question is that the symptoms of the person receiving care are
                  too severe for the level of care that they are engaging with.
                </p>
                <p className="lead">
                  The problem is that the people with the most information about
                  the patient, the family, do not always know or understand the
                  different levels of care available to help their loved one.
                </p>
                <p className="lead">
                  Below are descriptions of the various levels of care for
                  patients depending on the severity of their symptoms and
                  functional abilities.
                </p>
                {/* <Link to="/level-of-care" className="lead">
                  See Level Of Care descriptions.
                </Link> */}
              </div>
              {/*  <div
                className="col-md-9 col-lg-6 col-xl-5 order-lg-1"
                data-aos="fade-in"
              >
                <img
                  src="assets/img/about-2.jpg"
                  alt="About"
                  className="img-fluid rounded shadow"
                />
                 <img
                src="assets/img/about-2a.jpg"
                alt="About"
                className="position-absolute p-0 col-4 col-xl-5 border border-white border-thick rounded-circle top left shadow-lg mt-5 ml-n5 ml-lg-n3 ml-xl-n5 d-none d-md-block"
                data-jarallax-element="-20 0"
              />
              </div>*/}
            </div>
          </div>
        </section>
        <section className="pb-0 bg-light">
          <div className="container">
            {/* <div className="row section-title justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-7">
                <h3 className="display-4">In case of an emergency</h3>
                <div className="lead">
                  If you are having a psychiatric or medical emergency, please
                  CALL <mark>911</mark> or go directly to your nearest emergency
                  department.
                </div>
              </div>
            </div> */}
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-9 col-md-10">
                <LOCs />
              </div>
            </div>
            {/*
          <div className="row justify-content-center mt-3 mt-md-4">
            <div className="col-auto">
              <div className="alert bg-secondary">
                Don't see a good fit?{" "}
                <Link to="#">Submit your r&eacute;sum&eacute;</Link>
              </div>
            </div>
          </div>
        </div>
         <div className="divider divider-bottom bg-primary-3"></div> */}
          </div>
        </section>
      </Fragment>
    </Fragment>
  );
};
export default LevelOfCare;
