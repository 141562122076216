import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Header1 from "../../../img/bmbcpc-help-support.jpg";
import Header2 from "../../../img/bmbcpc-help-header-2.jpg";
import Header3 from "../../../img/bmbcpc-wellness.jpg";
import FlexSessions from "../../../img/sessions.jpg";
const Home = () => {
  return (
    <Fragment>
      <section className="p-0 border-top border-bottom row no-gutters">
        <div className="col-lg-7 col-xl-6">
          <div className="container min-vh-lg-80 d-flex align-items-center">
            <div className="row justify-content-center">
              <div className="col col-md-10 col-xl-9 text-center text-lg-left">
                <section>
                  <div data-aos="fade-right">
                    <h2 className="display-4">We're here to help you</h2>
                    <h3>
                      Providing hope and achieving change, wellness and recovery
                      during life’s difficult times.
                    </h3>
                  </div>
                  <div
                    className="d-flex flex-column flex-sm-row mt-4 mt-md-5 justify-content-center justify-content-lg-start"
                    data-aos="fade-right"
                    data-aos-delay="300"
                  >
                    <Link
                      to="/here-to-help"
                      className="btn btn-primary btn-lg mx-sm-2 mx-lg-0 mr-lg-2 my-1 my-sm-0"
                    >
                      Learn more
                    </Link>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-xl-6 d-lg-flex flex-lg-column">
          <div className="divider divider-side transform-flip-y bg-white d-none d-lg-block"></div>
          <div
            className="d-lg-flex flex-column flex-fill controls-hover"
            data-flickity='{ "imagesLoaded": true, "wrapAround":true, "pageDots":false, "autoPlay":true }'
          >
            <div className="carousel-cell text-center">
              <img className="img-fluid" src={Header1} alt="Here to Help!" />
            </div>
            <div className="carousel-cell text-center">
              <img
                className="img-fluid"
                src={Header2}
                alt="Always ready to help!"
              />
            </div>
            <div className="carousel-cell text-center">
              <img
                className="img-fluid"
                src={Header3}
                alt="Your wellness is important to us"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light o-hidden">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-9 col-xl-9">
              <h2 className="display-4 h2">Take charge of your life</h2>
              <div className="lead">
                Have you tried mental health treatment elsewhere and felt it was
                inadequate, or have you been feeling something is not quite
                right and you need to seek help for Depression, Anxiety, Mood
                swings, Impulsive or Compulsive or Obsessive behaviors, Stress
                and relationship problems, Substance use disorder, gambling and
                other mental health disorders? Give BMB Consultants a try and
                let us help you,{" "}
                <mark data-aos="highlight-text" data-aos-delay="200">
                  Live Your Best Life!
                </mark>
                .
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-0 bg-light pb-3">
        <div className="container">
          <div className="row align-items-center justify-content-around">
            <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
              <div className="text-center text-lg-left">
                <h2 className="h2">Flexible Sessions</h2>
                <p className="lead">
                  We offer both In-Person (on-site) appointment sessions at our
                  physical clinic location and Virtual (Telepsychiatry)
                  appointments
                </p>
              </div>
              <div id="faq-accordion">
                <div className="card mb-2 mb-md-3">
                  <a
                    href="#accordion-1"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    className="p-3 p-md-4"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="mb-0 mr-2">Physical Clinic</h6>
                      <img
                        src="assets/img/icons/interface/icon-caret-right.svg"
                        alt="Caret Right"
                        className="icon icon-sm"
                        data-inject-svg
                      />
                    </div>
                  </a>
                  <div
                    className="collapse"
                    id="accordion-1"
                    data-parent="#faq-accordion"
                  >
                    <div className="px-3 px-md-4 pb-3 pb-md-4">
                      Physical clinic and onsite in person are available for
                      fully vaccinated individuals who prefer in-person visits
                    </div>
                  </div>
                </div>
                <div className="card mb-2 mb-md-3">
                  <a
                    href="#accordion-2"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    className="p-3 p-md-4"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="mb-0 mr-2">Virtual (Telepsychiatry)</h6>
                      <img
                        src="assets/img/icons/interface/icon-caret-right.svg"
                        alt="Caret Right"
                        className="icon icon-sm"
                        data-inject-svg
                      />
                    </div>
                  </a>
                  <div
                    className="collapse"
                    id="accordion-2"
                    data-parent="#faq-accordion"
                  >
                    <div className="px-3 px-md-4 pb-3 pb-md-4">
                      Our Virtual (Telepsychiatry) appointments are done with a
                      HIPAA Compliant secure video-conferencing platform that
                      allow you have your session conveniently, safely and
                      securely from the privacy of your chosen remote location.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-lg-5" data-aos="fade-in">
              <img
                src={FlexSessions}
                alt="Flexible Sessions at BMBCPC"
                className="img-fluid rounded shadow"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-primary text-white">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-7">
              <h3 className="display-4">Testimonials</h3>
              <div className="lead">
                Life-changing results from our members.
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              className="d-lg-flex flex-wrap flex-column flex-fill justify-content-center controls-hover"
              data-flickity='{ "imagesLoaded": true, "wrapAround":true, "pageDots":true, "autoPlay":true }'
            >
              <div className="carousel-cell p-5">
                <div className="media rounded pl-3 pr-3 pr-md-4 py-2 shadow-sm bg-white ">
                  <div className="text-dark mb-0 p-3 ">
                    You have really helped me - Miss P
                  </div>
                </div>
              </div>
              <div className="carousel-cell p-5">
                <div className="media rounded pl-3 pr-3 pr-md-4 py-2 shadow-sm bg-white ">
                  <div className="text-dark mb-0 p-3">
                    &ldquo;I want to thank you for helping me, get back my
                    life&rdquo; - Dr. M
                  </div>
                </div>
              </div>
              <div className="carousel-cell p-5">
                <div className="media rounded pl-3 pr-3 pr-md-4 py-2 shadow-sm bg-white ">
                  <div className="text-dark mb-0 p-3">
                    &ldquo;For the first time I felt all my questions were
                    answered to my satisfaction&rdquo; - Mr. BO
                  </div>
                </div>
              </div>
              <div className="carousel-cell p-5">
                <div className="media rounded pl-3 pr-3 pr-md-4 py-2 shadow-sm bg-white ">
                  <div className="text-dark mb-0 p-3">
                    &ldquo;I finally have hope&rdquo; - Mr. VO
                  </div>
                </div>
              </div>
              <div className="carousel-cell p-5">
                <div className="media rounded  pl-3 pr-3 pr-md-4 py-2 shadow-sm bg-white">
                  <div className="text-dark mb-0 p-3">
                    &ldquo;I felt I was heard.&rdquo; - Ms JP
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default Home;
