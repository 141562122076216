import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { webapp } from "../modules/Constants";

export class Footer extends Component {
  render() {
    return (
      <Fragment>
        <section className="pb-0">
          <div className="container">
            <div className="row section-title justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-7">
                <h3 className="display-4">Let's help you</h3>
                <div className="lead">
                  Start today with a free mental health assessment
                </div>
              </div>
            </div>
            <div className="row justify-content-center text-center mt-md-n4">
              <div className="col-auto">
                <Link to="/new-patients" className="btn btn-primary btn-lg">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
          <div className="divider divider-bottom bg-primary-1 mt-5"></div>
        </section>

        <footer className="bg-primary-3 text-white links-white pb-4 footer-1">
          <div className="container">
            <div className="row">
              <div className="col-xl-auto mr-xl-5 col-md-4 mb-4 mb-md-0 col-lg-4">
                <h5>Quick Links</h5>
                <ul className="nav flex-row flex-md-column">
                  <li className="nav-item mr-3 mr-md-0">
                    <Link
                      to="/new-patients"
                      className="nav-link fade-page px-0 py-2"
                    >
                      New Patients
                    </Link>
                  </li>
                  <li className="nav-item mr-3 mr-md-0">
                    <a href={webapp} className="nav-link fade-page px-0 py-2">
                      Current Patients
                    </a>
                  </li>
                  <li className="nav-item mr-3 mr-md-0">
                    <Link
                      to="/contact"
                      className="nav-link fade-page px-0 py-2"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-xl-auto mr-xl-6 col-md-6 col-lg-6">
                <h5>Resources</h5>
                <ul className="nav flex-row flex-md-column">
                  <li className="nav-item mr-3 mr-md-0">
                    <Link
                      to="/level-of-care"
                      className="nav-link fade-page px-0 py-2"
                    >
                      Level Of Care
                    </Link>
                  </li>
                  <li className="nav-item mr-3 mr-md-0">
                    <Link
                      to="/resources"
                      className="nav-link fade-page px-0 py-2"
                    >
                      Resources
                    </Link>
                  </li>
                  <li className="nav-item mr-3 mr-md-0">
                    <a
                      href={webapp + "sign-up"}
                      className="nav-link fade-page px-0 py-2"
                    >
                      Sign Up
                    </a>
                  </li>
                </ul>
              </div>
              {/*<div className="col-xl-auto mr-xl-5 col-md-4 col-lg-4">
                <h5>Resources</h5>
                <ul className="nav flex-row flex-md-column">
                  <li className="nav-item mr-3 mr-md-0">
                    <Link to="#" className="nav-link fade-page px-0 py-2">
                      FAQs
                    </Link>
                  </li>
                  <li className="nav-item mr-3 mr-md-0">
                    <Link to="#" className="nav-link fade-page px-0 py-2">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item mr-3 mr-md-0">
                    <Link to="#" className="nav-link fade-page px-0 py-2">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-xl-auto mr-xl-5 col-md-4 col-lg-4">
                <h5>Resources</h5>
                <ul className="nav flex-row flex-md-column">
                  <li className="nav-item mr-3 mr-md-0">
                    <Link to="#" className="nav-link fade-page px-0 py-2">
                      FAQs
                    </Link>
                  </li>
                  <li className="nav-item mr-3 mr-md-0">
                    <Link to="#" className="nav-link fade-page px-0 py-2">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item mr-3 mr-md-0">
                    <Link to="#" className="nav-link fade-page px-0 py-2">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
               <div className="col mt-4 mt-md-0 mt-lg-5 mt-xl-0 order-lg-4 order-xl-3">
                <h5>Articles</h5>
                <ul className="list-unstyled d-flex flex-wrap">
                  <li className="col-12 col-lg-6 col-xl-12 px-0">
                    <div className="row my-2 my-md-3">
                      <Link className="col-5" to="#">
                        <img
                          className="rounded img-fluid hover-fade-out"
                          src="assets/img/blog/thumb-2.jpg"
                          alt="blog.1"
                        />
                      </Link>
                      <div className="col">
                        <Link className="h6" to="#">
                          Unveiling our new vision for Jumpstart
                        </Link>
                        <div className="text-small text-muted mt-2">
                          October 10th
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="col-12 col-lg-6 col-xl-12 px-0">
                    <div className="row my-2 my-md-3">
                      <Link className="col-5" to="#">
                        <img
                          className="rounded img-fluid hover-fade-out"
                          src="assets/img/blog/thumb-1.jpg"
                          alt="blog.2"
                        />
                      </Link>
                      <div className="col">
                        <Link className="h6" to="#">
                          Making the most of team-building sessions
                        </Link>
                        <div className="text-small text-muted mt-2">
                          October 2nd
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg mt-2 mt-md-5 mt-lg-0 order-lg-3 order-xl-4">
                <h5>Newsletter</h5>
                <div className="card card-body bg-white">
                  <p>
                    Receive free tips, articles, and our newsletter full of
                    helpful resources!
                  </p>
                  <form
                    data-form-email
                    novalidate
                    action="/forms/mailchimp.php"
                  >
                    <div className="d-flex flex-column flex-sm-row form-group">
                      <input
                        className="form-control mr-sm-2 mb-2 mb-sm-0 w-auto flex-grow-1"
                        name="email"
                        placeholder="Email Address"
                        type="email"
                        required
                      />
                      <button
                        type="submit"
                        className="btn btn-primary btn-loading"
                        data-loading-text="Sending"
                      >
                        <svg
                          className="icon bg-white"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <title>Icon For Loading</title>
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <g>
                              <polygon
                                points="0 0 24 0 24 24 0 24"
                                opacity="0"
                              ></polygon>
                            </g>
                            <path
                              d="M12,4 L12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,10.9603196 17.7360885,9.96126435 17.2402578,9.07513926 L18.9856052,8.09853149 C19.6473536,9.28117708 20,10.6161442 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
                            ></path>
                          </g>
                        </svg>
                        <span>Go</span>
                      </button>
                    </div>
                    <div
                      data-recaptcha
                      data-sitekey="INSERT_YOUR_RECAPTCHA_V2_SITEKEY_HERE"
                      data-size="invisible"
                      data-badge="bottomleft"
                    ></div>
                    <div
                      className="d-none alert alert-success w-100"
                      role="alert"
                      data-success-message
                    >
                      Thanks, a member of our team will be in touch shortly.
                    </div>
                    <div
                      className="d-none alert alert-danger w-100"
                      role="alert"
                      data-error-message
                    >
                      Please fill all fields correctly.
                    </div>
                    <div className="text-small text-muted">
                      We'll never share your email address
                    </div>
                  </form>
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col">
                <hr />
              </div>
            </div>
            <div className="row flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
              <div className="col-auto">
                <div className="d-flex flex-column flex-sm-row align-items-center text-small">
                  <div className="text-muted">
                    &copy; 2022 BMB Consultants P.C | All Rights Reserved
                  </div>
                </div>
              </div>
              <div className="col-auto mt-3 mt-lg-0">
                <ul className="list-unstyled d-flex mb-0">
                  <li className="mx-3">
                    <Link to="#" className="hover-fade-out">
                      <img
                        src="assets/img/icons/social/dribbble.svg"
                        alt="Dribbble"
                        className="icon icon-xs bg-white"
                        data-inject-svg
                      />
                    </Link>
                  </li>
                  <li className="mx-3">
                    <Link to="#" className="hover-fade-out">
                      <img
                        src="assets/img/icons/social/twitter.svg"
                        alt="Twitter"
                        className="icon icon-xs bg-white"
                        data-inject-svg
                      />
                    </Link>
                  </li>
                  <li className="mx-3">
                    <Link to="#" className="hover-fade-out">
                      <img
                        src="assets/img/icons/social/github.svg"
                        alt="Github"
                        className="icon icon-xs bg-white"
                        data-inject-svg
                      />
                    </Link>
                  </li>
                  <li className="mx-3">
                    <Link to="#" className="hover-fade-out">
                      <img
                        src="assets/img/icons/social/facebook.svg"
                        alt="Facebook"
                        className="icon icon-xs bg-white"
                        data-inject-svg
                      />
                    </Link>
                  </li>
                  <li className="mx-3">
                    <Link to="#" className="hover-fade-out">
                      <img
                        src="assets/img/icons/social/google.svg"
                        alt="Google"
                        className="icon icon-xs bg-white"
                        data-inject-svg
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        <Link
          to="#top"
          className="btn btn-primary rounded-circle btn-back-to-top"
          data-smooth-scroll
          data-aos="fade-up"
          data-aos-offset="2000"
          data-aos-mirror="true"
          data-aos-once="false"
        >
          <img
            src="assets/img/icons/interface/icon-arrow-up.svg"
            alt="Icon"
            className="icon bg-white"
            data-inject-svg
          />
        </Link>
      </Fragment>
    );
  }
}

export default Footer;
