import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { webapp } from "../modules/Constants";

export class Header extends Component {
  render() {
    return (
      <Fragment>
        <div className="navbar-container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container">
              <Link
                className="navbar-brand navbar-brand-dynamic-color fade-page flex-grow-1"
                to="/"
              >
                <img
                  alt="BMB Consultants, P.C - Brains, Minds & Behaviors"
                  src="/assets/img/logos/bmbpc_logo.png"
                  width="265px"
                />
              </Link>

              <div className="d-flex align-items-right order-lg-3 flex-grow-1 justify-content-end">
                {/* <Link
                  to="/new-patients"
                  className="btn btn-primary ml-lg-4 mr-3 mr-md-4 mr-lg-0 d-none d-sm-block order-lg-3"
                >
                  Get Started
                </Link> */}

                <button
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  className="navbar-toggler"
                  data-target=".navbar-collapse"
                  data-toggle="collapse"
                  type="button"
                >
                  <img
                    alt="Navbar Toggler Open Icon"
                    className="navbar-toggler-open icon icon-sm"
                    data-inject-svg
                    src="assets/img/icons/interface/icon-menu.svg"
                  />
                  <img
                    alt="Navbar Toggler Close Icon"
                    className="navbar-toggler-close icon icon-sm"
                    data-inject-svg
                    src="assets/img/icons/interface/icon-x.svg"
                  />
                </button>
              </div>

              <div className="collapse navbar-collapse order-3 order-lg-2 justify-content-lg-right">
                <ul className="navbar-nav my-3 my-lg-0">
                  <li className="nav-item">
                    <Link to="/about" className="nav-link">
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services" className="nav-link">
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/new-patients" className="nav-link">
                      New Patients
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a href={webapp} className="nav-link">
                      Current Patients
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link to="/contact" className="nav-link">
                      Contact
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/resources" className="nav-link">
                      Resources
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </Fragment>
    );
  }
}
export default Header;
