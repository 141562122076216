import React, { Fragment } from "react";
// import { Link } from "react-router-dom";
import ProfessionalCare from "../../../img/professional-bmb-hcp.jpg";
import CertifiedProfessionals from "../../../img/certified-pro.jpg";
const About = () => {
  return (
    <Fragment>
      <div className="bg-primary-3 o-hidden" data-overlay>
        <section className="text-white pb-0">
          <div className="container pb-5">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-9">
                <h1 className="display-3">
                  We Are Trained <br></br>Brains, Minds, and Behaviors <br></br>
                  Professional Consultants
                </h1>
                <h3 className=" mb-5">
                  Providing hope and achieving change, wellness and recovery
                  during life’s difficult times.
                </h3>
              </div>
            </div>
          </div>
          {/*<div
            className="position-absolute w-50 h-100 top left"
            data-jarallax-element="100 50"
          >
            <div className="blob blob-4 bg-gradient w-100 h-100 top left"></div>
          </div>
           <div className="divider divider-bottom bg-white"></div> */}
        </section>
      </div>
      <section className="pb-0">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-9">
              <h5>Our team at BMB Consultants, have a simple philosophy...</h5>
              <h3 className="display-4">
                'Treat all our clients in the same way we would want our family
                members to be treated’
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row align-items-center justify-content-around text-center text-lg-left">
            <div
              className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 order-lg-2"
              data-aos="fade-in"
            >
              <h2 className="h1">
                We understand the effort it takes for one to seek help for most
                personal and private problems.
              </h2>
              <h3 className="h3">
                Our goal is providing quality, compassionate, empathic, and
                non-judgmental care to all our clients.
              </h3>
            </div>
            <div
              className="col-md-9 col-lg-6 col-xl-5 order-lg-1"
              data-aos="fade-in"
            >
              <img
                src={ProfessionalCare}
                alt="Personalized Care"
                className="img-fluid rounded shadow"
              />
              {/* <img
                src="assets/img/about-2a.jpg"
                alt="About"
                className="position-absolute p-0 col-4 col-xl-5 border border-white border-thick rounded-circle top left shadow-lg mt-5 ml-n5 ml-lg-n3 ml-xl-n5 d-none d-md-block"
                data-jarallax-element="-20 0"
              /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0 o-hidden">
        <div className="container">
          <div className="row align-items-center justify-content-around text-center text-lg-left">
            <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
              <div data-aos="fade-in" data-aos-offset="250">
                <h2 className="h1">Certified Professionals to care for you</h2>
                <p className="lead">
                  Our Psychiatrists are Board Certified in General Psychiatry
                  and have additional Board Certifications as necessary to
                  address even the most complex case such as Addiction
                  Psychiatry/Medicine, Child and Adolescent Psychiatry and
                  Geriatric Psychiatry
                </p>
                <p className="lead">
                  {" "}
                  Our Therapists and Counselors are all Licensed and Certified
                  Psychotherapists with minimum of Masters’ Degrees in Social
                  Work. Our Psychiatric Nurses are Licensed Practical
                  Nurses/Registered Nurses with extensive experience working in
                  behavioral care settings.
                </p>
                <p className="lead">
                  {" "}
                  Administrative Staff are all trained professionals with
                  experience in working in behavioral health facilities.
                </p>
              </div>
            </div>
            <div
              className="col-md-9 col-lg-6 col-xl-5"
              data-aos="fade-in"
              data-aos-offset="250"
            >
              <img
                src={CertifiedProfessionals}
                alt="Certified Professionals to care for you"
                className="img-fluid rounded shadow"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary-3 text-white pt-5 pb-3">
        {/* <div className="divider divider-top transform-flip-x bg-light"></div> */}
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-9">
              <div className="lead">
                When receiving services at BMB Consultants' facilities, you can
                be assured that our entire team have your welfare as our primary
                concern.
              </div>
              <h3 className="display-4 pt-5">
                {" "}
                We are looking forward to partnering with you to Live Your Best
                Life!
              </h3>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default About;
