import React from "react";

const LOCs = () => {
  return (
    <>
      <div id="faq-accordion">
        <div className="card mb-2 mb-md-3">
          <a
            href="#accordion-1"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            className="p-3 p-md-4"
          >
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 mr-2">Outpatient Level of Care</h6>
              <img
                src="assets/img/icons/interface/icon-caret-right.svg"
                alt="Caret Right"
                className="icon icon-sm"
                data-inject-svg
              />
            </div>
          </a>
          <div
            className="collapse"
            id="accordion-1"
            data-parent="#faq-accordion"
          >
            <div className="px-3 px-md-4 pb-3 pb-md-4">
              This is the level of care most people are familiar with. to A
              patient may see their psychiatrist or nurse practitioner or
              therapist weekly or biweekly or monthly or at longer intervals
              depending on their symptoms and how they are progressing towards
              their treatment goals. The frequency of the visits with a provider
              can change as needed. Patients who have reached their treatment
              goals may be seen only three to four times a year. <br></br>
              <br></br>If a person going into outpatient care has severe
              symptoms, the providers involved may choose to increase the
              frequency of visits. If this is not effective, it may be time to
              consider a higher level of care.
            </div>
          </div>
        </div>
        <div className="card mb-2 mb-md-3">
          <a
            href="#accordion-2"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            className="p-3 p-md-4"
          >
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 mr-2">Intensive Outpatient Program (IOP)</h6>
              <img
                src="assets/img/icons/interface/icon-caret-right.svg"
                alt="Caret Right"
                className="icon icon-sm"
                data-inject-svg
              />
            </div>
          </a>
          <div
            className="collapse"
            id="accordion-2"
            data-parent="#faq-accordion"
          >
            <div className="px-3 px-md-4 pb-3 pb-md-4">
              In general, an IOP is three days out of the week for at least
              three hours per day. The programs can vary, possibly being more
              days and more hours. <br></br>
              <br></br>These programs will focus on group work to help the
              patient develop additional skills to manage their symptoms. The
              IOP can include medication management services, although not
              always.
            </div>
          </div>
        </div>
        <div className="card mb-2 mb-md-3">
          <a
            href="#accordion-3"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            className="p-3 p-md-4"
          >
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 mr-2">
                Partial Hospitalization Program (PHP) or “Day Treatment”
              </h6>
              <img
                src="assets/img/icons/interface/icon-caret-right.svg"
                alt="Caret Right"
                className="icon icon-sm"
                data-inject-svg
              />
            </div>
          </a>
          <div
            className="collapse"
            id="accordion-3"
            data-parent="#faq-accordion"
          >
            <div className="px-3 px-md-4 pb-3 pb-md-4">
              These types of programs are five days out of the week for six to
              eight hours per day. The program will focus on group work and
              forming a patient community, as well as psychiatric care. A PHP is
              normally more days than an IOP and will include psychiatric
              assessment and care. <br></br>
              <br></br>At this point in their care, patients, their families and
              their providers have identified that treatment needs to be the
              primary focus rather than work or other family responsibilities.
            </div>
          </div>
        </div>
        {/* 4 */}
        <div className="card mb-2 mb-md-3">
          <a
            href="#accordion-4"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            className="p-3 p-md-4"
          >
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 mr-2">
                Community Integration Program/Extended Care{" "}
              </h6>
              <img
                src="assets/img/icons/interface/icon-caret-right.svg"
                alt="Caret Right"
                className="icon icon-sm"
                data-inject-svg
              />
            </div>
          </a>
          <div
            className="collapse"
            id="accordion-4"
            data-parent="#faq-accordion"
          >
            <div className="px-3 px-md-4 pb-3 pb-md-4">
              In this level of care, patients are living in a shared therapeutic
              space with other patients, but the treatment does not happen in
              the space where the patients live. At some Programs, residents
              live in an apartment setting where there is support from resident
              advisors; however, group therapy and individual therapy happen in
              a separate location. This type of care allows the patient to
              maintain a certain level of independence and to focus on
              increasing their interaction with the community.
            </div>
          </div>
        </div>
        {/* 5 */}
        <div className="card mb-2 mb-md-3">
          <a
            href="#accordion-5"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            className="p-3 p-md-4"
          >
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 mr-2">
                Psychiatric Assertive Community Treatment (PACT){" "}
              </h6>
              <img
                src="assets/img/icons/interface/icon-caret-right.svg"
                alt="Caret Right"
                className="icon icon-sm"
                data-inject-svg
              />
            </div>
          </a>
          <div
            className="collapse"
            id="accordion-5"
            data-parent="#faq-accordion"
          >
            <div className="px-3 px-md-4 pb-3 pb-md-4">
              This level of care can best be described as a hospital without
              walls. In this model of care, one of the primary goals is to keep
              a person from being hospitalized. It may work well for people who
              have had multiple hospitalizations in their past and are trying to
              break that pattern. <br></br>In PACT, the patients live in their
              own home/apartment and the clinicians come to the patient, rather
              than the other way around. Although the PACT model is becoming
              better known, this is a level of care that is difficult to find
              outside of major cities.
            </div>
          </div>
        </div>
        {/* 6 */}
        <div className="card mb-2 mb-md-3">
          <a
            href="#accordion-6"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            className="p-3 p-md-4"
          >
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 mr-2">Residential Level of Care </h6>
              <img
                src="assets/img/icons/interface/icon-caret-right.svg"
                alt="Caret Right"
                className="icon icon-sm"
                data-inject-svg
              />
            </div>
          </a>
          <div
            className="collapse"
            id="accordion-6"
            data-parent="#faq-accordion"
          >
            <div className="px-3 px-md-4 pb-3 pb-md-4">
              In this level of care, patients are living in the place where they
              are receiving treatment. <br></br>This type of environment can
              create a safe environment for patients whose functioning is
              significantly impacted by their symptoms to the point where they
              are unable to live at home and make progress in their treatment.{" "}
              <br></br>Resident advisors provide 24-hour monitoring and support.
            </div>
          </div>
        </div>
        {/* 7 */}
        <div className="card mb-2 mb-md-3">
          <a
            href="#accordion-7"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            className="p-3 p-md-4"
          >
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 mr-2">Sub-acute Hospital Care </h6>
              <img
                src="assets/img/icons/interface/icon-caret-right.svg"
                alt="Caret Right"
                className="icon icon-sm"
                data-inject-svg
              />
            </div>
          </a>
          <div
            className="collapse"
            id="accordion-7"
            data-parent="#faq-accordion"
          >
            <div className="px-3 px-md-4 pb-3 pb-md-4">
              This is a level of care that is no longer available in most of the
              country. In this level of care, a patient is hospitalized for a
              longer period. <br></br>At some of these programs, patients stay
              in most programs for four to six weeks. There are other
              psychotherapeutic hospitals where patients might stay with the
              program for much longer, sometimes years.
              <br></br>These types of hospital stays are designed to help the
              patient stabilize their symptoms but also begin to identify and
              address some of the more significant core issues that have
              impacted the person's life. Psychiatric nursing staff provide
              24-hour care.
            </div>
          </div>
        </div>
        {/* 8 */}
        <div className="card mb-2 mb-md-3">
          <a
            href="#accordion-8"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            className="p-3 p-md-4"
          >
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 mr-2">Acute Care Hospital </h6>
              <img
                src="assets/img/icons/interface/icon-caret-right.svg"
                alt="Caret Right"
                className="icon icon-sm"
                data-inject-svg
              />
            </div>
          </a>
          <div
            className="collapse"
            id="accordion-8"
            data-parent="#faq-accordion"
          >
            <div className="px-3 px-md-4 pb-3 pb-md-4">
              This level of care offers the highest level of containment.
              Usually, people who are imminently suicidal or are experiencing
              acute psychotic/manic symptoms where they pose a safety risk
              utilize acute hospitalization. The environment is very restrictive
              as a result. These hospital stays are short, perhaps three to five
              days. <br></br>The focus is stabilization and safety. Often,
              patients and families may feel disappointed in the experience and
              feel that the core issues were not addressed. An acute hospital
              stay is not designed to address larger core issues; therefore,
              outpatient follow-up care is crucial to ensure the best outcome.{" "}
              <br></br>
              <br></br>A patient can move up the continuum of care as needed, or
              perhaps they end up in acute care and then move down the continuum
              as they stabilize. It is important to consider an alternative
              level of care if a patient is not making progress at their current
              level of care. <br></br>If someone feels stuck in their mental
              health, it would be beneficial for them to look at the level of
              care they are receiving services at and decide if it is what works
              best for their needs. It is possible a move in the level of care
              would dramatically help their mental health.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LOCs;
