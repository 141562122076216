import React, { Fragment } from "react";
// import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <Fragment>
      <div className="bg-primary-3 text-white p-0" data-overlay>
        <img
          src="assets/img/heros/hero-1.jpg"
          alt="Hero"
          className="bg-image opacity-60"
        />
        <section className="pb-0">
          <div className="container pb-3">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-7">
                <h1 className="display-3">We’re here to help</h1>
                <h3>
                  We make care simple, so you can focus on feeling better.
                </h3>
              </div>
            </div>
          </div>
          <div className="divider divider-bottom"></div>
        </section>
      </div>
      <section className="pb-0">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-7">
              <h3 className="display-4">Talk with us</h3>
              <div className="lead">
                We’ve redefined care from the ground up so that everyone can get
                great care, wherever they are. <br /> <br />
                Please complete and submit your inquiry below and we will
                respond as soon as possible. Please note submission of form does
                not establish a treatment relationship{" "}
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9 col-xl-8">
              <form data-form-email novalidate action="/forms/smtp.php">
                <div className="form-row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label for="contact-name">Your Name</label>
                      <input
                        type="text"
                        name="contact-name"
                        className="form-control"
                        id="contact-name"
                        required
                      />
                      <div className="invalid-feedback">
                        Please type your name.
                      </div>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="form-group">
                      <label for="contact-email">Email Address</label>
                      <input
                        type="email"
                        name="contact-email"
                        className="form-control"
                        id="contact-email"
                        placeholder="you@website.com"
                        required
                      />
                      <div className="invalid-feedback">
                        Please provide your email address.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label for="home-phone">Home Phone (optional)</label>
                      <input
                        type="text"
                        name="home-phone"
                        className="form-control"
                        id="home-phone"
                      />
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="form-group">
                      <label for="contact-phone">Mobile Phone (optional)</label>
                      <input
                        type="tel"
                        name="contact-phone"
                        className="form-control"
                        id="contact-phone"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label for="contact-message">Message</label>
                  <textarea
                    id="contact-message"
                    name="contact-message"
                    rows="10"
                    className="form-control"
                    required
                  ></textarea>
                  <div className="invalid-feedback">
                    Please tell us a little more.
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <div
                      data-recaptcha
                      data-sitekey="6Lce8wATAAAAAC4H_1gFDRtycz9KNzZRz-eZGy9J"
                    ></div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col">
                    <div
                      className="d-none alert alert-success"
                      role="alert"
                      data-success-message
                    >
                      Thanks, a member of our team will be in touch shortly.
                    </div>
                    <div
                      className="d-none alert alert-danger"
                      role="alert"
                      data-error-message
                    >
                      Please fill all fields correctly.
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary btn-loading"
                        type="submit"
                        data-loading-text="Sending"
                      >
                        {/* <!-- Icon for loading animation --> */}
                        <svg
                          className="icon bg-white"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <title>Icon For Loading</title>
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <g>
                              <polygon
                                points="0 0 24 0 24 24 0 24"
                                opacity="0"
                              ></polygon>
                            </g>
                            <path
                              d="M12,4 L12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,10.9603196 17.7360885,9.96126435 17.2402578,9.07513926 L18.9856052,8.09853149 C19.6473536,9.28117708 20,10.6161442 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
                            ></path>
                          </g>
                        </svg>
                        <span>Send Message</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-auto" data-aos="fade-up">
              <div className="px-md-4 px-lg-5 mb-5 mb-md-0">
                <h5>Email us</h5>
                <a href="mailto:contact@bmbcpc.com" className="lead">
                  contact@bmbcpc.com
                </a>
              </div>
            </div>
            <div
              className="col-md-auto"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="px-md-4 px-lg-5 mb-5 mb-md-0">
                <h5>Drop in</h5>
                <address>
                  <p>2626 Tilton Road,</p>
                  <p>Egg Harbor Township, </p>
                  <p>NJ, 08234</p>
                </address>
                <a
                  href="https://goo.gl/maps/H9ycSvRWdSLcdDHy9"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Directions
                </a>
              </div>
            </div>
            <div
              className="col-md-auto"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="px-md-4 px-lg-5 mb-5 mb-md-0">
                <h5>Call us</h5>
                <a href="tel:1-866-277-5223" className="lead">
                  1-866-277-5223
                </a>
                {"  |  "}
                <a href="tel:1-609 798 0555" className="lead">
                  Fax: 609-798-0555
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="divider divider-bottom bg-primary-3"></div> */}
    </Fragment>
  );
};
export default Contact;
