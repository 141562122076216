import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import ProvenTreament from "../../../img/proven-treatment.jpg";
import TailoredCare from "../../../img/tailored-care.jpg";

const Help = () => {
  return (
    <Fragment>
      <div className="bg-primary-3 o-hidden" data-overlay>
        <section className="text-white pb-0">
          <div className="container pb-5">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-9">
                <h1 className="display-3">
                  We are here
                  <br /> to{" "}
                  <mark data-aos="highlight-text" data-aos-delay="250">
                    help you
                  </mark>
                </h1>
                <h3 className="">
                  Providing hope and achieving change, wellness and recovery
                  during life’s difficult times.
                </h3>
              </div>
            </div>
          </div>
          {/*<div
            className="position-absolute w-50 h-100 top left"
            data-jarallax-element="100 50"
          >
            <div className="blob blob-4 bg-gradient w-100 h-100 top left"></div>
          </div>
           <div className="divider divider-bottom bg-white"></div> */}
        </section>
      </div>
      <section className="pb-0">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-7">
              <h3 className="display-4">
                BMB Consultants & You,{" "}
                <mark data-aos="highlight-text" data-aos-delay="250">
                  a partnership
                </mark>{" "}
                that works.
              </h3>
              <div className="lead">
                We achieve great results by partnering with our client to
                overcome their difficulties and live meaningful lives by
                utilizing the best scientific knowledge about how the Brain and
                Mind interacts to influence Behaviors and apply the best
                evidence-based treatment options to achieve healing.
              </div>
            </div>
          </div>

          <div className="row justify-content-center mt-4 mt-md-5">
            <div className="col-auto">
              <div className="alert bg-secondary">
                Getting started is simple.
                <Link to="/new-patients">
                  {" "}
                  take a free mental health assessment
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row align-items-center justify-content-around text-center text-lg-left">
            <div
              className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 order-lg-2"
              data-aos="fade-in"
            >
              <h2 className="h1">
                Are you looking for help and proven treatment
              </h2>
              <p className="lead">
                for Depression, Anxiety, Mood swings, Impulsive or compulsive or
                obsessive behaviors, Stress and relationship problems, Substance
                use disorder, gambling, and mental health disorder?
              </p>
              <Link to="/contact" className="lead">
                I need help right away? Contact Us
              </Link>
            </div>
            <div
              className="col-md-9 col-lg-6 col-xl-5 order-lg-1"
              data-aos="fade-in"
            >
              <img
                src={ProvenTreament}
                alt="Proven Treatment"
                className="img-fluid rounded shadow"
              />
              {/* <img
                src="assets/img/about-2a.jpg"
                alt="About"
                className="position-absolute p-0 col-4 col-xl-5 border border-white border-thick rounded-circle top left shadow-lg mt-5 ml-n5 ml-lg-n3 ml-xl-n5 d-none d-md-block"
                data-jarallax-element="-20 0"
              /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0 o-hidden">
        <div className="container">
          <div className="row align-items-center justify-content-around text-center text-lg-left">
            <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
              <div data-aos="fade-in" data-aos-offset="250">
                <h2 className="h1">Care tailored to you!</h2>
                <p className="lead">
                  We conduct comprehensive assessment tailored to each
                  individual client, then partner with our client to define
                  specific treatment goals and then use the best available
                  treatment option and plans based on current best scientific
                  evidence to achieve the goal
                </p>
                <p className="lead">
                  {" "}
                  We also ensure proactive monitoring of each of our client’s
                  progress towards defined goals using latest tools and
                  technology to allow for early detection of deviation from
                  goals and treatment and provide timely interventions.
                </p>
              </div>
            </div>
            <div
              className="col-md-9 col-lg-6 col-xl-5"
              data-aos="fade-in"
              data-aos-offset="250"
            >
              <img
                src={TailoredCare}
                alt="Tailored Care"
                className="img-fluid rounded shadow"
              />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default Help;
