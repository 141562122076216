import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { webapp } from "../modules/Constants";
import NewPatientG from "../../../img/new-pat.jpg";
import NewPatientGua from "../../../img/new-bmbcpc-pat.jpg";

const NewPatients = () => {
  return (
    <Fragment>
      <div className="bg-primary-3 o-hidden" data-overlay>
        <section className="text-white pb-0">
          <div className="container pb-5">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-9">
                <h1 className="display-3">New Patients Guide</h1>
                <h3>
                  At BMB Consultants, all prospective new clients begin with an
                  <mark data-aos="highlight-text" data-aos-delay="250">
                    {" "}
                    Initial Screening Assessment.
                  </mark>
                </h3>
               
              </div>
            </div>
          </div>
          {/*<div
            className="position-absolute w-50 h-100 top left"
            data-jarallax-element="100 50"
          >
            <div className="blob blob-4 bg-gradient w-100 h-100 top left"></div>
          </div>
           <div className="divider divider-bottom bg-white"></div> */}
        </section>
      </div>
      <div className="row justify-content-center mt-2 mt-md-5 mb-3">
        <div className="col-auto">
          <div className="alert h3 bg-secondary">
            Take the
            <a href={webapp + "sign-up"}> Initial Screening Assessment</a>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="row align-items-center justify-content-around text-center text-lg-left">
            <div
              className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 order-lg-2"
              data-aos="fade-in"
            >
              <h2 className="h1">Initial Screening Assessment</h2>
              <p className="lead">
                The purpose of this assessment is to determine if a prospective
                client is suitable for the{" "}
                <mark data-aos="highlight-text" data-aos-delay="250">
                  Level Of Care (LOC){" "}
                </mark>
                that BMB Consultants, P.C. currently offers at any given time.
              </p>
              <Link to="/level-of-care" className="lead">
                See Level Of Care descriptions.
              </Link>
              <p className="lead mt-3">
                {" "}
                It is very important that every client seeking help, is getting
                the help he or she needs at the Level Of Care that is most
                appropriate for them. This is because recovery and good outcomes
                are tied to patients receiving the right treatment at the right
                time, in the correct and least restrictive treatment setting.
              </p>
            </div>
            <div
              className="col-md-9 col-lg-6 col-xl-5 order-lg-1"
              data-aos="fade-in"
            >
              <img
                src={NewPatientG}
                alt="New Patients"
                className="img-fluid rounded shadow"
              />
              {/* <img
                src="assets/img/about-2a.jpg"
                alt="About"
                className="position-absolute p-0 col-4 col-xl-5 border border-white border-thick rounded-circle top left shadow-lg mt-5 ml-n5 ml-lg-n3 ml-xl-n5 d-none d-md-block"
                data-jarallax-element="-20 0"
              /> */}
            </div>
          </div>
        </div>
      </section>
      <div className="row justify-content-center mt-2 mt-md-5 mb-3">
        <div className="col-auto">
          <div className="alert h3 bg-secondary">
            Take the
            <a href={webapp + "sign-up"}> Initial Screening Assessment</a>
          </div>
        </div>
      </div>
      <section className="bg-primary-3 text-white pt-5">
        {/* <div className="divider divider-top transform-flip-x bg-light"></div> */}
        <div className="container">
          <section>
            <div className="container">
              <div className="row align-items-center justify-content-around text-center text-lg-left">
                <div
                  className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 order-lg-2"
                  data-aos="fade-in"
                >
                  <p className="lead">
                    BMB Consultants currently provide treatment at Outpatient
                    level of care with Pharmacological (Medication) Management
                    visits, Individual therapy, Couples Therapy and Family
                    Therapy.
                  </p>

                  <p className="lead">
                    Establishment of a treatment relationship is{" "}
                    <strong>NOT IMPLIED OR GUARANTEED </strong>
                    at or after the Initial Screening assessment. This is
                    because BMB Consultants P.C. and you do not currently have
                    therapeutic relationship.
                  </p>
                </div>
                <div
                  className="col-md-9 col-lg-6 col-xl-5 order-lg-1"
                  data-aos="fade-in"
                >
                  <img
                    src={NewPatientGua}
                    alt="New Patients Screening"
                    className="img-fluid rounded shadow"
                  />
                  {/* <img
                src="assets/img/about-2a.jpg"
                alt="About"
                className="position-absolute p-0 col-4 col-xl-5 border border-white border-thick rounded-circle top left shadow-lg mt-5 ml-n5 ml-lg-n3 ml-xl-n5 d-none d-md-block"
                data-jarallax-element="-20 0"
              /> */}
                </div>
              </div>
            </div>
          </section>
          <div className="row justify-content-center mt-2 mt-md-5 mb-3">
            <div className="col-auto">
              <div className="alert">
                Take the
                <a href={webapp + "sign-up"}> Initial Screening Assessment</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default NewPatients;
