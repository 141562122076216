import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/theme.scss";
import HttpsRedirect from "react-https-redirect";

import Home from "./components/content/pages/Home";
import Header from "./components/content/layout/Header";
import Footer from "./components/content/layout/Footer";
import NewPatients from "./components/content/pages/NewPatients";
import Services from "./components/content/pages/Services";
import Contact from "./components/content/pages/Contact";
import About from "./components/content/pages/About";
import Help from "./components/content/pages/Help";
import Resources from "./components/content/pages/Resources";
import LevelOfCare from "./components/content/pages/LevelOfCare";

function App() {
  return (
    <HttpsRedirect>
      <BrowserRouter forceRefresh={true}>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/resources" component={Resources} />
          <Route exact path="/new-patients" component={NewPatients} />
          <Route exact path="/level-of-care" component={LevelOfCare} />
          <Route exact path="/here-to-help" component={Help} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </HttpsRedirect>
  );
}

export default App;
